.react-navigation-transparent {
	padding: 0.1rem 1rem;
	background: transparent !important;
}

.react-navigation {
	padding: 0.1rem 1rem;
	background: linear-gradient(90deg, #92C026, 60%, #4AAD3E);
	transition: transform 1s
}

.react-navigation .nav-link {
	color: white !important;
	font-weight: bold;
}

.react-navigation-transparent .nav-link {
	color: white !important;
	font-weight: bold;
}

.hidden {
	transform: translateY(-100%);
}

.react-navigation .special-link {
	color: yellow !important;
}