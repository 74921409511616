.speakers-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 20px;
}

.load-more-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.load-more {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 5vw;
	min-width: 200px;
	min-height: 50px;
	border: 1px solid green;
	line-height: 1;
	font-size: calc(12px + 1vw);
	cursor: pointer;
}

.load-more-divider {
	flex: 1;
	height: 0;
	border: 0.5px solid green;
}

.speaker-card {
	display: flex;
	flex-direction: row;
	padding:  calc(20px + 1.5vw) calc(10px + 0.1vw);
	min-width: 300px;
	align-items: center;
}

.speaker-card .text-container {
	display: flex;
	padding-left: 1vw;
	width: calc(100px + 5vw);
	height: calc(20px + 3vw);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.speaker-card .name {
	font-weight: bold;
	text-align: left;
	line-height: 1.2;
	color: green;
	min-width: 200px;
}

.speaker-card .title {
	padding-top: calc(0.2vw + 3px);
	text-align: left;
	line-height: 1.2;
	color: grey;
	min-width: 200px;
}

.speaker-card .image {
	width: 8vw;
	height: 8vw;
	cursor: pointer;
	max-width: 80px;
	max-height: 80px;
}

.modal-container {
	display: flex;
	flex-direction: row;
}

.modal-container .image {
	width: 12vw;
	height: 12vw;
	padding: 1vw;
	min-width: 120px;
	min-height: 120px;
}

.modal-header {
	display: flex;
	flex-direction: column;
}

.modal-body {
	max-height: 300px;
	overflow: scroll;
}

.icon-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	color: green;
}

.icon-container .icon {
	background: green;
	width: 3vw;
	height: 3vw;
	padding: 1vw;
}

.agenda-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}