body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-navigation-transparent {
	padding: 0.1rem 1rem;
	background: transparent !important;
}

.react-navigation {
	padding: 0.1rem 1rem;
	background: -webkit-linear-gradient(left, #92C026, 60%, #4AAD3E);
	background: linear-gradient(90deg, #92C026, 60%, #4AAD3E);
	-webkit-transition: -webkit-transform 1s;
	transition: -webkit-transform 1s;
	transition: transform 1s;
	transition: transform 1s, -webkit-transform 1s
}

.react-navigation .nav-link {
	color: white !important;
	font-weight: bold;
}

.react-navigation-transparent .nav-link {
	color: white !important;
	font-weight: bold;
}

.hidden {
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
}

.react-navigation .special-link {
	color: yellow !important;
}
.count-down {
	padding: 20px 0;
}

.count-down .count-number {
	font-size: calc(5px + 5vw);
	font-weight: 500;
}

.count-down .count-label {
	font-size: calc(5px + 1.5vw);
	font-weight: 500;
}
.button-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-top: 10px;
	margin-bottom: 50px;
}

.button-buy {
	margin-top: 10px;
	background: -webkit-linear-gradient(right, #154360, #2471A3);
	background: linear-gradient(-90deg, #154360, #2471A3);
	border: none;
	font-weight: bold;
	width: calc(200px + 10vw);
}

.icon-buy {
	padding:10px;
}

.buy-with-neo-form {
	display: none;
}

.speakers-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 20px;
}

.load-more-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.load-more {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1;
	height: 5vw;
	min-width: 200px;
	min-height: 50px;
	border: 1px solid green;
	line-height: 1;
	font-size: calc(12px + 1vw);
	cursor: pointer;
}

.load-more-divider {
	flex: 1 1;
	height: 0;
	border: 0.5px solid green;
}

.speaker-card {
	display: flex;
	flex-direction: row;
	padding:  calc(20px + 1.5vw) calc(10px + 0.1vw);
	min-width: 300px;
	align-items: center;
}

.speaker-card .text-container {
	display: flex;
	padding-left: 1vw;
	width: calc(100px + 5vw);
	height: calc(20px + 3vw);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.speaker-card .name {
	font-weight: bold;
	text-align: left;
	line-height: 1.2;
	color: green;
	min-width: 200px;
}

.speaker-card .title {
	padding-top: calc(0.2vw + 3px);
	text-align: left;
	line-height: 1.2;
	color: grey;
	min-width: 200px;
}

.speaker-card .image {
	width: 8vw;
	height: 8vw;
	cursor: pointer;
	max-width: 80px;
	max-height: 80px;
}

.modal-container {
	display: flex;
	flex-direction: row;
}

.modal-container .image {
	width: 12vw;
	height: 12vw;
	padding: 1vw;
	min-width: 120px;
	min-height: 120px;
}

.modal-header {
	display: flex;
	flex-direction: column;
}

.modal-body {
	max-height: 300px;
	overflow: scroll;
}

.icon-container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	color: green;
}

.icon-container .icon {
	background: green;
	width: 3vw;
	height: 3vw;
	padding: 1vw;
}

.agenda-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.tab-container {
	padding: 30px 10%;
}
.tab-container p {
	color: white;
	text-align: left;
	padding: 1rem;
}

@media only screen and (max-width: 768px) {
    .tab-container {
        padding: 30px 0;
	}
	
	.tab-container .relative-tab-title .badge {
		left: 0 !important;
		bottom: 100% !important;
	}
}

@media only screen and (max-width: 536px) {
	.tab-container .relative-tab-title .badge {
		left: 100% !important;
		bottom: 50% !important;
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size:  cals(1.5vw + 3px);
	font-weight: bold;
}

.tab {
	min-width: 10px;
}

.tab-container .list-title {
	color: white;
	background:-webkit-linear-gradient(bottom, #80BE43, 60%, #4BA545);
	background:linear-gradient(1800deg, #80BE43, 60%, #4BA545);
}

.tab-container ul {
	text-align: left;
	background-color: white;
}
.tab-container a:hover{
	color: white;
}
.tab-container .text-weighter{
	font-size: calc(15px + 1.5vw);
	font-weight: bolder;
}

.tab-container .text-lighter{
	font-size: calc(10px + 1.2vw);
	font-weight: 100;
	padding-left: 2vw;
}

.agenda-event-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.agenda-event-container .image {
	width: 10vw;
	max-width: 50px;
	height: 10vw;
	max-height: 50px;
}

.agenda-event-container .special-image {
	background-color: #4BA545;
}

.agenda-event-container .image + .image {
	margin-top:-1vw;
}

.agenda-event-container .image-container {
	display: flex;
	flex-direction: column;
}

.agenda-event-container .detail-container {
	padding-left: 2vw;
}

.agenda-event-container .detail-container .by {
	font-size: 0.8rem;
}

.agenda-event-container .detail-container .by-name {
	color: green;
	text-decoration: underline;
}

.agenda-event-container .detail-container .by-title {
	color: grey;
}

.tab-container .relative-tab-title {
	position: relative;
}

.tab-container .relative-tab-title .badge {
	position: absolute;
    padding: 0.5em;
    left: 100%;
	bottom: 50%;
	color: white;
    background-color: grey;
}

.well-lg {
    padding: 2vw;
}

.well {
    background-color: #e6f3c7;
    border: 1px solid #4aad3e;
	min-height: 20px;
	margin: 5% 10%;
}

.well .email {
	color:green;
}

.email-notify-content {
	padding-top: 30px;
}

.email-notify-content p {
	margin-bottom: 0;
}

.email-notify-content img {
	padding-top: 20px;
	width: calc(100px + 30vw);
	max-width: 300px;
}

.react-email-form {
	display: flex;
	justify-content: center;
	padding: 20px 15% 50px 15%;
}

.react-email-form input {
	max-width: 300px;
	min-width: 200px;
}

.react-email-form button {
	background: -webkit-linear-gradient(right, #154360, #2471A3);
	background: linear-gradient(-90deg, #154360, #2471A3);
}

.modal-body-email-info {
	text-align: center;
}
.live-broadcast-content {
    padding: 0.1vw;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.live-boardcast-container {
    padding-bottom: 3vw;
}

.youtube-live {
    min-width: 403px;
    min-height: 226px;
}
